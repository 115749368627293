import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { CustomDot } from "../Reusable/dot-line"
import { Container, Row, Col } from "react-bootstrap"
import { CustomAccordion } from "./Accordion"
import gambar1 from "../../images/BT-1.png"
import gambar2 from "../../images/BT-2.png"
import currencyRightImg from "../../images/currency-coin-right.png"
import currencyLeftImg from "../../images/currency-coin-left.png"
import bgGreyRotate from "../../images/logo-big-grey-rotate.svg"
import { IdComponent } from "../Reusable/id-components"
import { BackgroundImg } from "./HowToUse"

export const HowToStep = ({ json, isEnglish }) => {
  const { Verified, TopUp, Transfer, Bills } = json
  const StepData = [
    {
      eventId: "0",
      idName: "wallet",
      name: TopUp.Title,
      video: "lgchGX96XvY",
      videoLabel: isEnglish
        ? `Check out this video to know how to top up your e-wallet`
        : `Lihat video cara top up e-wallet`,
      bodyData: [
        {
          eventId: "0",
          name: TopUp.Point1,
          content: TopUp.Desc1,
        },
        {
          eventId: "1",
          name: TopUp.Point2,
          content: TopUp.Desc2,
        },
        {
          eventId: "2",
          name: TopUp.Point3,
          content: TopUp.Desc3,
        },
        {
          eventId: "3",
          name: TopUp.Point4,
          content: TopUp.Desc4,
        },
        {
          eventId: "4",
          name: TopUp.Point5,
          content: TopUp.Desc5,
        },
        {
          eventId: "5",
          name: TopUp.Point6,
          content: TopUp.Desc6,
        },
      ],
    },
    {
      eventId: "1",
      idName: "uang",
      name: Transfer.Title,
      video: "eh-US0EEtBI",
      videoLabel: isEnglish
        ? `Check out this video to find out how to transfer from Singapore`
        : `Lihat video cara transfer dari Singapura`,
      bodyData: [
        {
          eventId: "0",
          name: Transfer.Point1,
          content: Transfer.Desc1,
        },
        {
          eventId: "1",
          name: Transfer.Point2,
          content: Transfer.Desc2,
        },
        {
          eventId: "2",
          name: Transfer.Point3,
          content: Transfer.Desc3,
        },
        {
          eventId: "3",
          name: Transfer.Point4,
          content: Transfer.Desc4,
        },
        {
          eventId: "4",
          name: Transfer.Point5,
          content: Transfer.Desc5,
        },
        {
          eventId: "5",
          name: Transfer.Point6,
          content: Transfer.Desc6,
        },
        {
          eventId: "6",
          name: Transfer.Point7,
          content: Transfer.Desc7,
        },
        {
          eventId: "7",
          name: Transfer.Point8,
          content: Transfer.Desc8,
        },
        {
          eventId: "8",
          name: Transfer.Point9,
          content: Transfer.Desc9,
        },
      ],
    },
    {
      eventId: "2",
      idName: "tagihan",
      name: Bills.Title,
      video: "KsHwcVvV7xA",
      videoLabel: isEnglish
        ? `Check out this video to know how to pay bills from Singapore`
        : `Lihat video cara bayar tagihan dari Singapura`,
      bodyData: [
        {
          eventId: "0",
          name: Bills.Point1,
          content: Bills.Desc1,
        },
        {
          eventId: "1",
          name: Bills.Point2,
          content: Bills.Desc2,
        },
        {
          eventId: "2",
          name: Bills.Point3,
          content: Bills.Desc3,
        },
        {
          eventId: "3",
          name: Bills.Point4,
          content: Bills.Desc4,
        },
        {
          eventId: "4",
          name: Bills.Point5,
          content: Bills.Desc5,
        },
        {
          eventId: "5",
          name: Bills.Point6,
          content: Bills.Desc6,
        },
        {
          eventId: "6",
          name: Bills.Point7,
          content: Bills.Desc7,
        },
      ],
    },
  ]

  return (
    <>
      <IdComponent title="how-to" />
      <CustomContainer>
        <CustomTitle>{isEnglish ? "How To Use" : "Cara Pakai"}</CustomTitle>
        <CustomAccordion data={StepData} isSg />
        <CurrencyImg
          alt="currency"
          src={currencyLeftImg}
          top="70px"
          left="-200px"
        />
        <CurrencyImg
          alt="currency"
          src={currencyRightImg}
          top="70px"
          right="-240px"
        />
        <BackgroundImg src={bgGreyRotate} alt="logo" top="-300px" />
      </CustomContainer>
    </>
  )
}

const CustomContainer = styled(Container)`
  position: relative;
  min-height: 800px;
  padding-top: 100px;
  padding-bottom: 50px;
  padding-left: 50px;
`

const CustomTitle = styled.h2`
  color: #102c57;
  font-family: "Typefez-extrabold";
  font-size: 50px;
`

const CurrencyImg = styled.img`
  position: absolute;
  left: ${p => p.left};
  right: ${p => p.right};
  top: ${p => p.top};
  @media (max-width: 768px) {
    display: none;
  }
`
