import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import TextTransition, { presets } from "react-text-transition"
import { useStateIfMounted } from "use-state-if-mounted"
import NavigationBar from "../HeaderComponents/NavigationBar"
import Calculator from "../Calculator/Calculator"
import { CustomDot } from "../Reusable/dot-line"
import Legal from "./Legal" 
import { Banner } from "./Banner"
import Typewriter from "typewriter-effect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"

export const HeaderSg = ({ props, propsLink, isEnglish, isSg }) => {
  const [statusNav, setStatusNav] = useStateIfMounted(false)

  const statusNavbar = status => {
    setStatusNav(status)
  }

  const TitleHeader = isEnglish
    ? "A faster and lower cost way from Singapore to Indonesia to "
    : "Cara hemat dan cepat dari Singapura ke Indonesia untuk "

  const dotColor = `<span style="color: #ffd27c;">.</span>`
  const downloadText = isEnglish ? `Download Now` : `Download Sekarang`
  const spanSpace = `<span style="color: #FFF;">.</span>`

  const Texts = isEnglish
    ? [
        `send money${dotColor}`,
        `pay water bill${dotColor}`,
        `buy PLN tokens${dotColor}`,
        `pay BPJS${dotColor}`,
        `buy phone credit${dotColor}`,
      ]
    : [
        `kirim uang${dotColor}`,
        `bayar tagihan air${dotColor}`,
        `beli token listrik${dotColor}`,
        `bayar BPJS${dotColor}`,
        `beli pulsa${dotColor}`,
      ]

  return (
    <MainPageHeader style={{paddingTop: "80px" }}>
      {/* <NavigationBar
        props={props}
        propsLink={propsLink}
        parentCallback={statusNavbar}
        noToggleBusiness
      /> */}
      <Container className="pt-5">
        <Row>
          <Col md={7}>
            <TitleH1>
              {TitleHeader}
              <br />
              <span
                style={{
                  color: "#166F7B",
                }}
              >
                <Typewriter
                  options={{
                    strings: Texts,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>
              {/* <CustomDot /> */}
            </TitleH1>
            <Banner isSg />
          </Col>
          <CustomColCalc md={5}>
            <Calculator propsLink={propsLink} />
            <LegalDiv mtopres={isSg ? "10%" : '-5%'}>
              <DownloadNowDiv>
                <DownloadNowText>{downloadText}</DownloadNowText>
                <Arrow icon={faCaretDown} />
              </DownloadNowDiv>
              <Legal props={props} onlyCTA />
            </LegalDiv>
          </CustomColCalc>
        </Row>
      </Container>
    </MainPageHeader>
  )
}

const MainPageHeader = styled.div`
  min-height: 650px;
  padding-bottom: 40px;
  @media (max-width: 576px) {
    padding-bottom: 150px !important;
  }
`

const TitleH1 = styled.h2`
  font-family: "GothamBold";
  font-size: 38px;
  color: #cacaca;
  padding-right: 10px;
  @media (min-width: 768px) {
    text-align: left;
  }
`

const LegalDiv = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  @media (max-width: 576px) {
    position: relative;
    margin-top: ${p => p.mtopres};
  }
`

const CustomColCalc = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  @media (max-width: 576px) {
    align-items: center;
    margin-top: 50px;
  }
`

const DownloadNowDiv = styled.div`
  text-align: center;
  width: 350px;
  position: absolute;
  top: -50%;
  @media (min-width: 576px) {
    left: 25%;
  }
`

const DownloadNowText = styled.h2`
  font-family: "GothamBold";
  font-size: 24px;
  color: #48a9b5;
  margin-bottom: 0;
`

const Arrow = styled(FontAwesomeIcon)`
  color: #cacaca;
  font-size: 20px;
`
