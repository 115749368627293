import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { CustomDot } from "../Reusable/dot-line"
import moneySvg from "../../images/feature-money.svg"
import billSvg from "../../images/feature-bill.svg"
import walletSvg from "../../images/feature-wallet.svg"
import { IdComponent } from "../Reusable/id-components"

export const Feature = ({ props }) => {
  const {
    Title,
    Point1,
    Point1a,
    Desc1,
    Point2,
    Point2a,
    Desc2,
    Point3,
    Point3a,
    Desc3,
  } = props
  return (
    <>
    <IdComponent title="feature" />
    <CustomContainer>
      <CustomTitle>
        {Title}
        <CustomDot />
      </CustomTitle>
      <Container className="my-5">
        <Row>
          <CustomCol md={4} className="text-center">
            <CustomImg src={moneySvg} alt="money-svg"/>
            <CustomPoint>
              {Point1}
              <br />
              {Point1a}
            </CustomPoint>
            <CustomDesc>{Desc1}</CustomDesc>
          </CustomCol>
          <CustomCol md={4} className="text-center">
            <CustomImg src={billSvg} alt="bill-svg"/>
            <CustomPoint>
              {Point2}
              <br />
              {Point2a}
            </CustomPoint>
            <CustomDesc>{Desc2}</CustomDesc>
          </CustomCol>
          <CustomCol md={4} className="text-center">
            <CustomImg src={walletSvg} alt="wallet-svg"/>
            <CustomPoint>
              {Point3}
              <br />
              <span style={{ fontFamily: "GothamLight", fontStyle: "italic" }}>
                {Point3a}
              </span>
            </CustomPoint>
            <CustomDesc>{Desc3}</CustomDesc>
          </CustomCol>
        </Row>
      </Container>
    </CustomContainer>
    </>
  )
}

const CustomContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 50px;
`

const CustomTitle = styled.h2`
  color: #48a9b5;
  font-family: "GothamBold";
  font-size: 36px;
`

const CustomCol = styled(Col)`
  padding: 0px 50px;
  @media (max-width: 576px) {
    margin-bottom: 2rem;
  }
`

const CustomImg = styled.img`
  margin-bottom: 2rem;
`

const CustomPoint = styled.h3`
  font-size: 18px;
  color: #166e7b;
  margin-bottom: 2rem;
  @media (max-width: 576px) {
    margin-bottom: 0.5rem;
  }
`

const CustomDesc = styled.p`
  color: #828282;
  font-size: 14px;
`
