import React from "react"
import { useLocation } from "@reach/router"
import { MainPageJson, PathJson, HowToSgJson } from "../components/location"
import Seo from "../components/SEOComponents/Seo"
import { HeaderSg } from "../components/HomeComponents/HeaderSg"
import VideoMainSection from "../components/VideoMainSection/VideoMainSection"
import { Advantages } from "../components/HomeComponents/Advantages"
import { HowItWorks } from "../components/HomeComponents/HowItWorks"
import { Feature } from "../components/HomeComponents/Feature"
import { HowToStep } from "../components/HomeComponents/HowToStep"
import Testimonies from "../components/HomeComponents/Testimonies"
import Partner from "../components/HomeComponents/Partner"
import BlogContainer from "../components/HomeComponents/BlogContainer"
import Media from "../components/SendMoney/Media"
import Investor from "../components/HomeComponents/Investor"
import MissionSection from "../components/Mission Section/MissionSection"
import Footer from "../components/FooterComponents/Footer"
import Download from "../components/HomeComponents/Download"
import FeatureSection from "../components/FeatureSection/FeatureSection"
import { NavigationBar } from "../components/NavigationBar/parent"
import { Header } from "../components/HomeComponents/Header"
import rsr from "react-string-replace"
import styled from "styled-components"

function singapore() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const HowToContent = HowToSgJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  return (
    <div className="maxwidth">
      <Seo title="sg" />
      <Header
        props={MainPageContent.Header}
        propsLink={PathContent}
        isEnglish={isEnglish}
        title={rsr(MainPageContent.Header.TitleSg, "{sendMoney}", () => (
          <TitleEmp>{MainPageContent.Header.TitleSg1}</TitleEmp>
        ))}
      />
      <NavigationBar />
      <VideoMainSection
        props={MainPageContent.Video}
        isSg
        isEnglish={isEnglish}
      />
      <FeatureSection props={MainPageContent.Advantages} />
      <HowItWorks props={MainPageContent.HowItWorks} isEnglish={isEnglish} />
      <HowToStep json={HowToContent} isEnglish={isEnglish} />
      <Testimonies />
      <BlogContainer isEnglish={isEnglish} />
      <Download props={MainPageContent.Download} />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
      {/* <Advantages props={MainPageContent.Advantages} /> */}
      {/* <Feature props={MainPageContent.SgFeatures} /> */}
      {/* <Partner isEnglish={isEnglish} /> */}
      {/* <Media mainPage={true} /> */}
      {/* <Investor isEnglish={isEnglish} /> */}
      {/* <MissionSection props={MainPageContent.Mission} propsLink={PathContent} /> */}
    </div>
  )
}

const TitleEmp = styled.span`
  color: #0f2c57;
`

export default singapore
